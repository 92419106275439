import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'


const asapd = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="qvm">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Australian Sporting Alliance for People with a Disability</h1>
          <span className="highlight"></span>
        </span>
        <span>Disability Sports</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/asapd.jpg'} alt="ASAPD" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
            <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />
            </div>
            <a className="button visitwebsite" href="https://asapd.org/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a> 

          </div>

        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>The Australian Sporting Alliance for People with a Disability (ASAPD) collaborates, advocates, and facilitates inclusive sport and physical activity for people with a disability, impairment, or limitation.</p>
        <p>We developed a comprehensive WordPress website focused on disability sports, featuring rich content and a regularly updated blog. The site includes an article about me, with a link for readers to <a href="https://asapd.org/qa-with-toby-pattullo/" rel="noreferrer" target="_blank">learn more</a>. The website is optimised for responsiveness, ensuring a seamless experience across desktop, tablet, and smartphone devices.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/asapd-desktop.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/asapd-mobile.jpg'} alt="Mobile View" />
      </div>
      </div>

    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/braille-sign-supplies" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">Braille Sign Supplies</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/promentor" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">Promentor</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default asapd


export const Head = () => (
  <>
    <title>ASAPD - Tobstar</title>
<meta name="description" content="The Australian Sporting Alliance for People with a Disability (ASAPD) collaborates, advocates, and facilitates inclusive sport and physical activity for people with a disability, impairment, or limitation." />
  </>
)